
import { Options, Vue } from "vue-class-component";
import CustomBtn from "@/components/Mobile/CustomBtn.vue";
import { kMobileComponyInfo } from "@/config/news";

@Options({
  components: {
    CustomBtn,
  },
  data() {
    return {
      items: kMobileComponyInfo.ccps,
    };
  },
})
export default class Delivery extends Vue {}
