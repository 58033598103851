import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fff79868"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "m_news_page" }
const _hoisted_2 = {
  key: 0,
  id: "m_news_page_p_content"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  id: "m_news_page_img_list"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 3,
  id: "m_news_page_img_single"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 4,
  id: "m_news_page_img_both"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { key: 5 }
const _hoisted_11 = {
  id: "m_news_page_video",
  controls: "",
  name: "media"
}
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$props.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (item.type == 'p')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (content, j) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: `p_${index}_${j}`,
                  id: "m_news_content_text"
                }, _toDisplayString(content), 1))
              }), 128))
            ]))
          : (item.type == 'img_list_scroll')
            ? (_openBlock(), _createBlock(_component_Swiper, {
                key: 1,
                navigation: true,
                id: "m_news_page_img_list"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (content, j) => {
                    return (_openBlock(), _createBlock(_component_SwiperSlide, {
                      key: `img_list_${index}_${j}`
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: require(`@/assets/${content}`),
                          id: "m_img_item"
                        }, null, 8, _hoisted_3)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 2
              }, 1024))
            : (item.type == 'img_list')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (content, j) => {
                    return (_openBlock(), _createElementBlock("img", {
                      key: `img_list_${index}_${j}`,
                      src: content,
                      id: "m_img_item"
                    }, null, 8, _hoisted_5))
                  }), 128))
                ]))
              : (item.type == 'img')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (content, j) => {
                      return (_openBlock(), _createElementBlock("img", {
                        key: `img_${index}_${j}`,
                        src: content,
                        id: "m_img_single"
                      }, null, 8, _hoisted_7))
                    }), 128))
                  ]))
                : (item.type == 'img_both')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (content, j) => {
                        return (_openBlock(), _createElementBlock("img", {
                          key: `img_${index}_${j}`,
                          src: content,
                          id: "m_img_both"
                        }, null, 8, _hoisted_9))
                      }), 128))
                    ]))
                  : (item.type == 'video')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("video", _hoisted_11, [
                          _createElementVNode("source", {
                            src: item.videoUrl,
                            type: "video/mp4"
                          }, null, 8, _hoisted_12)
                        ])
                      ]))
                    : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}