import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "page_withFooter" }
const _hoisted_2 = { id: "page_container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Layout = _resolveComponent("Layout")!
  const _component_NewsPage = _resolveComponent("NewsPage")!
  const _component_Delivery = _resolveComponent("Delivery")!
  const _component_Claim = _resolveComponent("Claim")!
  const _component_PageFooter = _resolveComponent("PageFooter")!
  const _component_CustomSlider = _resolveComponent("CustomSlider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.selectedIndex==0)
          ? (_openBlock(), _createBlock(_component_Layout, { key: 0 }))
          : (_ctx.selectedIndex<4)
            ? (_openBlock(), _createBlock(_component_NewsPage, {
                key: 1,
                items: _ctx.pages[_ctx.selectedIndex]
              }, null, 8, ["items"]))
            : (_ctx.selectedIndex === 4)
              ? (_openBlock(), _createBlock(_component_Delivery, { key: 2 }))
              : (_ctx.selectedIndex === 5)
                ? (_openBlock(), _createBlock(_component_Claim, { key: 3 }))
                : _createCommentVNode("", true)
      ]),
      _createVNode(_component_PageFooter)
    ]),
    _createVNode(_component_CustomSlider, {
      items: _ctx.items,
      onSwitchTab: _ctx.handleSwitchTab
    }, null, 8, ["items", "onSwitchTab"])
  ], 64))
}