
import { Options, Vue } from "vue-class-component";
import CustomSlider from "@/components/Mobile/CustomSlider.vue";
import NewsPage from "@/components/Mobile/NewsPage.vue";
import Delivery from "./Delivery.vue";
import { KPCComponyLayout } from "@/config/news";

@Options({
  components: {
    CustomSlider,
    NewsPage,
    Delivery,
  },
  data() {
    return {
      selectedIndex: 0,
      pages: KPCComponyLayout,
    };
  },
  methods: {
    handleSwitchTab(index: number) {
      this.selectedIndex = index;
    },
  },
})
export default class Page extends Vue {}
