
import { Options, Vue } from "vue-class-component";
import CustomSlider from "@/components/Mobile/CustomSlider.vue";
import NewsPage from "@/components/Mobile/NewsPage.vue";
import Delivery from "./Delivery.vue";
import Layout from "./Layout.vue";
import PageFooter from "@/components/Mobile/PageFooter.vue";
import { kMobileMenusConfig } from "@/config/Mobile/MenusConfig";
import { kMobileComponyInfo } from "@/config/news";
import Claim from "../News/index.vue";

@Options({
  components: {
    CustomSlider,
    NewsPage,
    Delivery,
    Layout,
    PageFooter,
    Claim,
  },
  data() {
    return {
      selectedIndex: 0,
      items: kMobileMenusConfig.jj,
      pages: [
        kMobileComponyInfo.jz,
        kMobileComponyInfo.gnc,
        kMobileComponyInfo.swly,
        kMobileComponyInfo.wmlh,
      ],
    };
  },
  methods: {
    handleSwitchTab(index: number) {
      this.selectedIndex = index;
    },
  },
})
export default class Page extends Vue {}
