<template>
  <div id="page_container_normal">
    <BrandHours />
    <MediaNews :info="data_ob"/>
    <MediaNews :info="data_mtxw"/>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import BrandHours from "./SubDiv/BrandHours.vue";
import MediaNews from "./SubDiv/MediaNews.vue";
import { kMobileNewsLatest, kMobileNewsInfo } from "@/config/newsData";

@Options({
  components: {
    BrandHours,
    MediaNews,
  },
  data() {
    return {
      data_ob: kMobileNewsLatest.latest,
      data_mtxw: kMobileNewsInfo.mtxw,
    };
  },
})
export default class News extends Vue {}
</script>

<style>
@import url("../../../less/mobile/page.less");
</style>