
import { Options, Vue } from "vue-class-component";
import BoxWithNews from "@/components/Mobile/BoxWithNews.vue";

@Options({
  components: {
    BoxWithNews,
  },
  props: {
    info: Object,
  },
  data() {
    return {
      page: 0,
      // info: kMobileNewsLatest.latest,
    };
  },
  methods: {
    onClickSwith(index: number) {
      if (this.selectedYear !== index) {
        this.selectedYear = index;
      }
    },
  },
})
export default class MediaNews extends Vue {}
