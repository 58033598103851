
import { Options, Vue } from "vue-class-component";
import { kMobileNewsInfo } from "@/config/newsData";
import BoxWithDetail from "@/components/Mobile/BoxWithDetail.vue";

@Options({
  components: {
    BoxWithDetail,
  },
  data() {
    return {
      selectedYear: 0,
      info: kMobileNewsInfo.pbry,
    };
  },
  methods: {
    onClickSwith(index: number) {
      if (this.selectedYear !== index) {
        this.selectedYear = index;
      }
    },
  },
})
export default class BrandHours extends Vue {}
