import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-beefb992"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "m_d_news_page" }
const _hoisted_2 = {
  key: 0,
  id: "news_page_p_content"
}
const _hoisted_3 = {
  key: 1,
  id: "btns"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 3,
  id: "news_page_img_single"
}
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomBtn = _resolveComponent("CustomBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (item.type == 'p')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (content, j) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: `p_${index}_${j}`,
                  id: "news_page_content_text"
                }, _toDisplayString(content), 1))
              }), 128))
            ]))
          : (item.type == 'btns')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (config, index) => {
                  return (_openBlock(), _createBlock(_component_CustomBtn, {
                    key: index,
                    config: config
                  }, null, 8, ["config"]))
                }), 128))
              ]))
            : (item.type == 'title')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (content, j) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: `t_${index}_${j}`,
                      id: "title"
                    }, _toDisplayString(content), 1))
                  }), 128))
                ]))
              : (item.type == 'img')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (content, j) => {
                      return (_openBlock(), _createElementBlock("img", {
                        key: `img_${index}_${j}`,
                        src: require(`@/assets/${content}`),
                        id: "img_single"
                      }, null, 8, _hoisted_6))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}